import { request } from "graphql-request";
import { useAuth } from "@hooks/auth";
import { OperationDefinitionNode } from "graphql";
import { parse } from "graphql/language/parser";
import { report } from "@app/esource/error";
import { notification } from "@reifyhealth/picasso-pkg";
import { useIdleTimerContext } from "react-idle-timer";
import { GRAPHQL_URL } from "./constants";

type ErrorCodeMessage = Record<string, string>;

export type ErrorHandler = (
  operationName: string,
  query: string,
  options: any,
  reason: any,
) => any;

// until we get a better handle on how to display errors coming from Bengal,
// we will show one generic error message to the user, rather than show the
// unparsed JSON error message included in the API-Gateway response
const handleGenericErrorNotification = ({
  content,
  operationName,
}: {
  content: string;
  operationName: string;
}) => {
  const somethingWentWrongErrorConfig = {
    message: "Error",
    description: "Something went wrong. Please try again.",
    duration: 5,
  };
  report(new Error("Graphql codegen fetcher failed"), {
    content,
    operationName,
  });
  notification.destroy();
  notification.error(somethingWentWrongErrorConfig);
};

const showError = (content: string, key: string, operationName: string) => {
  if (!content) {
    content = '"Unknown Error"';
  }

  if (!key) {
    key = content;
  }

  handleGenericErrorNotification({ content, operationName });
};

const showDetailedError = (content: any, operationName: string) => {
  const errorCode: keyof ErrorCodeMessage =
    content.extensions?.response?.body?.errors?.at(0);

  if (errorCode) {
    console.error({ errorCode });
    return;
  }

  handleGenericErrorNotification({ content, operationName });
};

export const defaultErrorHandler = (
  operationName: string,
  query: string,
  _options: any,
  reason: any,
) => {
  if (reason?.response?.error) {
    if (reason?.response?.error instanceof Error) {
      report(reason?.response?.error, { operationName });
    }
    showError(reason?.response?.error, query, operationName);
  } else {
    if (reason?.response?.errors[0] instanceof Error) {
      report(reason?.response?.errors[0], {
        operationName,
        totalErrorCount: reason.response.errors.length(),
      });
    }

    reason?.response?.errors.map((obj: any) => {
      return showDetailedError(obj, operationName);
    }) || showError(reason?.toString(), query, operationName);
  }

  return Promise.reject(reason);
};

/**
 * To transform data before React query caches it,
 * [it is recommended](https://github.com/TanStack/query/discussions/3387#discussioncomment-2341522)
 * to transform the data after fetching. The benefit of this option over the
 * `select` `useQuery` parameter
 * (see https://tanstack.com/query/v4/docs/react/reference/useQuery)
 * is that subsequent comparisons on data change (e.g., useEffect dependencies)
 * are not re-evaluated if the transformed data is returned by the cache (i.e.
 * based on referential equality comparisons).
 */
function transformAfterFetch<TData>(options: any) {
  const postFetchTransform = options?.meta?.postFetchTransform;
  return (value: TData) =>
    postFetchTransform ? postFetchTransform(value) : value;
}

export function useCodegenFetcher<TData, TVariables>(
  query: string,
  _variables?: TVariables,
  _options?: RequestInit["headers"],
) {
  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth();
  const { activate } = useIdleTimerContext();

  return async (variables?: TVariables, options?: any): Promise<TData> => {
    const errorHandler = options?.meta?.errorHandler
      ? options.meta.errorHandler
      : defaultErrorHandler;

    if (isAuthenticated) {
      try {
        // eslint-disable-next-line no-var
        var accessToken = await getAccessTokenSilently?.();
        activate();
      } catch (e) {
        const userMessage = "Unable to get access token.";
        logout();
        if (e instanceof Error) {
          report(e, { userMessage });
        }
      }
    }

    const headers: { Authorization?: string } = {};

    // eslint-disable-next-line
    if (accessToken!) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const parsedOperation = parse(query);
    const operationNode = parsedOperation.definitions.find(
      (f) => f.kind === "OperationDefinition",
    )! as OperationDefinitionNode;
    const operationName = operationNode.name!.value;

    return request<TData, TVariables>(
      `${GRAPHQL_URL}?operationName=${operationName}&service=esource`,
      query,
      variables,
      headers,
    )
      .then(transformAfterFetch(options))
      .catch(errorHandler.bind(null, operationName, query, options));
  };
}
