import * as picassoLd from "@reifyhealth/picasso-launchdarkly";
import { pick } from "lodash";
import config from "config";
import { LDFlagSet } from "launchdarkly-js-client-sdk";
import { useFlags as useLDFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useSearchParams } from "react-router-dom";

const getFlagValueFromString = (value: string) => {
  if (["true", "1", ""].includes(value)) {
    return true;
  }

  if (["false", "0"].includes(value)) {
    return false;
  }

  // support string values
  return value;
};

const override = (ldFlags: LDFlagSet, queryParams: URLSearchParams) => {
  const searchAsObject = Object.fromEntries(new URLSearchParams(queryParams));
  const flags: LDFlagSet = Object.keys(searchAsObject)
    .filter((key) => key.indexOf("flag-") === 0)
    .reduce(
      (allFlags, flagKey) => ({
        ...allFlags,
        // trim the leading flag- prefix out of the flag name
        [flagKey.substring(5)]: getFlagValueFromString(searchAsObject[flagKey]),
      }),
      ldFlags,
    );

  return flags;
};

export enum Flags {
  testKey = "testKey", // used only for unit tests
  DOCUMENTS_TABLE_COLUMN_UPDATES = "CARE-8570-documents-table-column-updates",
  FILTER_ACCESSIBLE_TRIALS_BY_DOA = "CARE-8322-filter-accessible-trials-by-doa",
  INVALIDATED_INVESTIGATOR_SIGNATURES = "CARE-7880-invalidated-investigator-signatures",
  MAPPING_UPLOADS_TO_VISITS = "CARE-8313-mapping-uploads-to-visits",
  SWITCH_TO_SITE = "CARE-9612-switch-to-site-navigation",
  DOCUMENT_DETAILS_OVERHAUL = "CARE-9560-single-documents-view-overhaul",
  CHANGE_VISIT_DISPLAY = "CARE-9171-change-visit-display",
  DDC_TABLE_FIELDS_IMPROVEMENTS = "CARE-9675-ddc-table-field-display-improvements",
  REMOVE_CURRENT_USERS_AND_ROLE_CHANGES = "CARE-9714-remove-current-users-and-role-changes",
  TRIAL_VISIT_COMPLETION_DASHBOARD = "CARE-9712-trial-visit-completion-dashboard",
  UPLOADS_FROM_VISITS = "CTMS-107-uploads-from-visits",
  EXPECTED_UPLOADS_FROM_VISITS = "CTMS-83-expected-uploads",
}

const SOURCE_FLAG_NAMES = [
  "CARE-3200-declare-end-of-study", // This does not exist in LaunchDarkly
  "CARE-4238-signature-name", // This does not exist in LaunchDarkly
  "CARE-4581-release-notes",
  "CARE-5191-delegation-end-date", // This does not exist in LaunchDarkly
  "CARE-5335-delegation-details-mini-signature",
  "CARE-6159-end-delegation-signature",
  "testKey", // used only for unit tests
  "test-key",
  "CARE-7880-invalidated-investigator-signatures",
  "CARE-8313-mapping-uploads-to-visits",
  "CARE-8322-filter-accessible-trials-by-doa",
  "CARE-8570-documents-table-column-updates",
  "CARE-8417-decline-signature",
  "CARE-8673-add-document-log-to-pdf",
  "CARE-9071-include-visit-info-in-doc-headers",
  "CARE-9100-is-legacy-trial",
  "CARE-9171-change-visit-display",
  "CARE-9612-switch-to-site-navigation",
  "CARE-9560-single-documents-view-overhaul",
  "CARE-9675-ddc-table-field-display-improvements",
  "CARE-9714-remove-current-users-and-role-changes",
  "CARE-9712-trial-visit-completion-dashboard",
  "CTMS-107-uploads-from-visits",
  "CTMS-83-expected-uploads",
] as const;

export type SourceFlagSet = Record<
  (typeof SOURCE_FLAG_NAMES)[number],
  boolean | { current: boolean; previous: boolean } | undefined
>;

/**
 * Returns an object containing all Source flag values.
 *
 * To add a new flag, update the `SOURCE_FLAG_NAMES` constant.
 */
export const useLaunchDarklyFlags = (): SourceFlagSet => {
  const allFlags = picassoLd.useFlags("none");
  const [queryParams] = useSearchParams();

  if (config.isNotProduction()) {
    return pick(override(allFlags, queryParams), SOURCE_FLAG_NAMES);
  }

  return pick(allFlags, SOURCE_FLAG_NAMES);
};

const useFlags = () => {
  const ldFlags = useLDFlags();
  const [queryParams] = useSearchParams();

  const flags = React.useMemo(
    () => (config.isNotProduction() ? override(ldFlags, queryParams) : ldFlags),
    [ldFlags, queryParams],
  );

  return flags;
};

export default useFlags;
